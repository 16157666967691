import { memoryApi } from '@/app-api/newApi/memories';
import { CreateMemoryDto } from '@/app-api/newApi/memories/dto';
import {
  GetMemory,
  GetMemoryData,
  MyDraftsApi,
  PublishMemory,
  RecentPublishedApi,
} from '@/app-api/oldApi';
import { createAsyncThunk } from '@reduxjs/toolkit';
import {
  draftMemoryObject,
  draftMemoryResponse,
  draftObject,
  draftResponse,
  getMemoryObject,
  PublishMemoryObject,
  recentObject,
  recentResponse,
  singleMemoryObject,
  singleMemoryResponse,
} from '../util';
import { IGetMemoryParams } from './memory.types';

const getDraftMemoriesWithOldApi = createAsyncThunk(
  'memories/getDraftMemoriesWithOldApi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const data = draftObject(obj);
      const response = await MyDraftsApi(data);
      return fulfillWithValue(draftResponse(response));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getAllMemoriesWithNewApi = createAsyncThunk(
  'memories/getAllMemoriesWithNewApi',
  async (obj: IGetMemoryParams, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await memoryApi.getMemories(obj);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getCurrentUserMemoriesWithOldApi = createAsyncThunk(
  'memories/getCurrentUserMemoriesWithOldApi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const data = recentObject(obj);
      const response = await RecentPublishedApi(data);
      return fulfillWithValue(recentResponse(response));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getMemoryByIdWithNewApi = createAsyncThunk(
  'memories/getMemoryByIdWithNewApi',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const response = await memoryApi.getMemoryById(id);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getMemoryByIdWithOldApi = createAsyncThunk(
  'memories/getMemoryByIdWithOldApi',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const data = singleMemoryObject(id);
      const response = await GetMemory(data);
      return fulfillWithValue(singleMemoryResponse(response));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const getMemoryToEditWithOldApi = createAsyncThunk(
  'memories/getMemoryToEditWithOldApi',
  async (id: string, { rejectWithValue, fulfillWithValue }) => {
    try {
      const data = getMemoryObject(id);
      return fulfillWithValue(await GetMemoryData(data));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createMemoryWithOldApi = createAsyncThunk(
  'memories/createMemoryWithOldApi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const data = PublishMemoryObject(obj);
      const response = await PublishMemory(data);
      return fulfillWithValue(draftMemoryResponse(response));
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const createMemoryWithNewApi = createAsyncThunk(
  'memories/createMemoryWithNewApi',
  async (obj: CreateMemoryDto, { rejectWithValue, fulfillWithValue }) => {
    try {
      const payload = obj;
      const response = await memoryApi.createMemory(payload);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const editMemoryWithNewApi = createAsyncThunk(
  'memories/editMemoryWithNewApi',
  async (
    obj: { data: CreateMemoryDto; nid: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const payload = obj.data;
      const response = await memoryApi.editMemoryById(obj.nid, payload);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const editMemoryWithOldApi = createAsyncThunk(
  'memories/editMemoryWithNewApi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const payload = PublishMemoryObject(obj);
      const response = await PublishMemory(payload);
      return fulfillWithValue(response);
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const draftMemoryWithOldApi = createAsyncThunk(
  'memories/draftMemoryWithOldApi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const payload = draftMemoryObject(obj);
      const response = await PublishMemory(payload);
      return fulfillWithValue(draftMemoryResponse(response));
    } catch (error) {
      rejectWithValue(error);
    }
  }
);

const draftMemoryWithNewApi = createAsyncThunk(
  'memories/draftMemoryWithNewApi',
  async (
    obj: { data: CreateMemoryDto },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const payload = Object.assign({}, obj.data);
      payload.status = 2; // draft the memory
      const response = await memoryApi.createMemory(payload);
      return fulfillWithValue(response);
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

const draftCreatedMemoryWithNewApi = createAsyncThunk(
  'memories/draftCreatedMemoryWithNewApi',
  async (
    obj: { data: CreateMemoryDto; nid: string },
    { rejectWithValue, fulfillWithValue }
  ) => {
    try {
      const payload = Object.assign({}, obj.data);
      payload.status = 2; // draft the memory
      const response = await memoryApi.editMemoryById(obj.nid, payload);
      return fulfillWithValue(response);
    } catch (error) {
      // console.log(error);
      return rejectWithValue(error);
    }
  }
);

export const memoryActions = {
  getDraftMemoriesWithOldApi,
  getAllMemoriesWithNewApi,
  getCurrentUserMemoriesWithOldApi,
  getMemoryByIdWithOldApi,
  getMemoryByIdWithNewApi,
  getMemoryToEditWithOldApi,
  createMemoryWithOldApi,
  createMemoryWithNewApi,
  editMemoryWithNewApi,
  editMemoryWithOldApi,
  draftMemoryWithOldApi,
  draftMemoryWithNewApi,
  draftCreatedMemoryWithNewApi,
};
