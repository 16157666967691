import { AxiosRequestConfig, AxiosResponse } from 'axios';
import { Api } from '../api';
import {
  ChangePasswordDto,
  FederatedSignInDto,
  RegisterDto,
  ResendActivationEmailDto,
  ResetPasswordDto,
  SignInRequestDto,
  UpdateConfigDto,
  UpdateUserInfoDto,
  VerifyAccountDto,
  SignInResponseDto,
  FederatedSignInResponseDto,
  ResetPasswordResponseDto,
  ForgotPasswordResponseDto,
  UpdateUserInfoResponseDto,
  ChangePasswordResponseDto,
  RegisterResponseDto,
  VerifyAccountResponseDto,
  UpdateConfigResponseDto,
  ResendActivationEmailResponseDto,
  GetUserInfoResponseDto,
} from './dto';
import {
  getChangePasswordUrl,
  getFederatedSignInUrl,
  getInitiateForgotPasswordUrl,
  getRegisterUrl,
  getResendActivationEmailUrl,
  getResetPasswordUrl,
  getSignInUrl,
  getUpdateUserConfigUrl,
  getUpdateUserInfoUrl,
  getUserInfoUrl,
  getVerifyAccountUrl,
} from './urls';

const baseUrl = () => {
  return process.env.GATSBY_VERCEL_RELEASE_1_2 === 'TRUE' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'main' ||
    process.env.GATSBY_AWS_ENV === 'PROD'
    ? 'https://user.api.mystoriesmatter.com'
    : process.env.GATSBY_AWS_ENV === 'QA'
    ? 'https://qa-user.api.mystoriesmatter.com'
    : 'https://dev-user.api.mystoriesmatter.com';
};

class UserApi extends Api {
  public constructor(config?: AxiosRequestConfig) {
    super(config || {});

    // this middleware is been called right before the http request is made.
    this.interceptors.request.use((param: AxiosRequestConfig) => ({
      ...param,
    }));

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use((param: AxiosResponse) => ({
      ...param,
    }));
  }

  public signIn = (signInDto: SignInRequestDto): Promise<SignInResponseDto> => {
    return this.post(getSignInUrl(), signInDto);
  };

  public federatedSignIn = (
    federatedSignInDto: FederatedSignInDto
  ): Promise<FederatedSignInResponseDto> => {
    return this.post(getFederatedSignInUrl(), federatedSignInDto);
  };

  public initiateForgotPassword = (
    email: string
  ): Promise<ForgotPasswordResponseDto> => {
    return this.post(getInitiateForgotPasswordUrl(), { email });
  };

  public resetPassword = (
    resetPasswordDto: ResetPasswordDto
  ): Promise<ResetPasswordResponseDto> => {
    return this.put(getResetPasswordUrl(), resetPasswordDto);
  };

  public updateUserInfo = (
    updateUserInfoDto: UpdateUserInfoDto
  ): Promise<UpdateUserInfoResponseDto> => {
    return this.patch(getUpdateUserInfoUrl(), updateUserInfoDto);
  };

  public getUserInfo = (
    token?: string,
    refreshToken?: string
  ): Promise<GetUserInfoResponseDto> => {
    let config = undefined;
    if (token && refreshToken) {
      config = {
        headers: {
          Authorization: token,
          refreshToken: refreshToken,
        },
      };
    }
    return this.get(getUserInfoUrl(), config);
  };

  public changePassword = (
    changePasswordDto: ChangePasswordDto
  ): Promise<ChangePasswordResponseDto> => {
    return this.put(getChangePasswordUrl(), changePasswordDto);
  };

  public register = (
    registerDto: RegisterDto
  ): Promise<RegisterResponseDto> => {
    return this.post(getRegisterUrl(), registerDto);
  };

  public verifyAccount = (
    verifyAccountDto: VerifyAccountDto
  ): Promise<VerifyAccountResponseDto> => {
    return this.patch(
      getVerifyAccountUrl({ id: verifyAccountDto.id }),
      verifyAccountDto.data
    );
  };

  public updateConfig = (
    updateConfigDto: UpdateConfigDto
  ): Promise<UpdateConfigResponseDto> => {
    return this.patch(getUpdateUserConfigUrl(), updateConfigDto);
  };

  public resendActivationEmail = (
    resendActivationEmailDto: ResendActivationEmailDto
  ): Promise<ResendActivationEmailResponseDto> => {
    return this.post(getResendActivationEmailUrl(), resendActivationEmailDto);
  };
}

export const userApi = new UserApi({
  baseURL: baseUrl(),
});
