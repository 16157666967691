const API_VERSION = 'api/v1';
const MICROSERVICE_NAME = 'users';

export const getSignInUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/authenticate`;

export const getFederatedSignInUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/add-cognito-user`;

export const getInitiateForgotPasswordUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/forgot-password`;

export const getResetPasswordUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/confirm-password`;

export const getUpdateUserInfoUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/`;

export const getUserInfoUrl = () => `${API_VERSION}/${MICROSERVICE_NAME}/`;

export const getChangePasswordUrl = () => `${API_VERSION}/${MICROSERVICE_NAME}/authentication/change-password`;

export const getRegisterUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/register`;

export const getVerifyAccountUrl = (obj: { id: string }) =>
  `${API_VERSION}/${MICROSERVICE_NAME}/admin/confirm-user/${obj.id}`;

export const getUpdateUserConfigUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/config`;

export const getResendActivationEmailUrl = () =>
  `${API_VERSION}/${MICROSERVICE_NAME}/authentication/resend-email`;
