// Import Lora font
import '@fontsource/lora/500.css';
import '@fontsource/lora/600.css';
import React from 'react';
// Import Inter Font
import '@fontsource/inter/300.css';
import '@fontsource/inter/400.css';
import '@fontsource/inter/500.css';
import '@fontsource/inter/700.css';

// Import CSS Reset from https://meyerweb.com/eric/tools/css/reset/
import './src/styles/reset.css';
import { loginActions } from '@/app/reducers/Login/login.actions';
// Wrap App with Redux Store Provider
import WrapWithProvider from './src/app/wrap-with-provider';
import Amplify, { Auth, Hub } from 'aws-amplify';

import configData from '@/config/cognitoConfig';
import { setAmplifyError } from './src/app/reducers/Login/login.slice';
import { store } from './src/app/Store';

export const wrapRootElement = (props) => {
  Amplify.Logger.LOG_LEVEL =
    process.env.NODE_ENV === 'development' ? 'DEBUG' : 'INFO';
  Amplify.configure({
    Auth: {
      userPoolId: configData.userPool,
      userPoolWebClientId: configData.clientId,
      region: configData.region,
      oauth: {
        domain: configData.userPoolUri,
        scope: configData.tokenScopes,
        redirectSignIn: configData.callbackUri,
        redirectSignOut: configData.signoutUri,
        responseType: 'code',
      },
    },
  });
  Hub.listen('auth', ({ payload }) => {
    const { event, data } = payload;
    let message = null;
    switch (event) {
      case 'cognitoHostedUI_failure':
        try {
          message = data.message.split('+').join(' ');
        } catch (error) {
          message = null;
        }
        store.dispatch(setAmplifyError(message));
        break;
      case 'cognitoHostedUI':
        store.dispatch(loginActions.googleLogin({}));
        break;
      default:
        console.log();
    }
  });

  return <WrapWithProvider {...props} />;
};
