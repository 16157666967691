import axios from 'axios';
import { GetServerDataProps } from 'gatsby';
import Cookies from 'universal-cookie';

export const loginResponse = (oldResponse: any) => {
  // this function converts old api response into new API response
  const newResponse = {
    code: oldResponse.ResponseCode,
    message: oldResponse.ResponseMessage,
    data: {
      uid: oldResponse.Response?.UserID,
      auth_time: Math.floor(new Date().getTime() / 1000),
      email: oldResponse.Response?.email ? oldResponse.Response.email : '',
      firstName: oldResponse.Response?.FirstName,
      lastName: oldResponse.Response?.LastName,
      fullName:
        oldResponse.Response?.FirstName + ' ' + oldResponse.Response?.LastName,
      exp: Math.floor(new Date().getTime() / 1000) + 24,
      token: {
        accessToken: oldResponse.Response?.UserAuthToken,
        idToken: oldResponse.Response?.UserAuthToken,
        refreshToken: oldResponse.Response?.UserAuthToken,
      },
      config: {
        introductoryData: 'false',
      },
    },
  };
  return newResponse;
};

export const draftObject = (obj: any) => {
  const newObj = {
    details: {
      type: 'mine',
      mine_type: 'all',
      offset: obj.params.pageIndex,
      limit: obj.params.pageSize,
    },
  };
  return newObj;
};

export const draftResponse = (obj: any) => {
  const dataArr = obj.Data;
  if (dataArr) {
    dataArr.data.map((item: any) => {
      item.memoryID = item.nid;
      item.author = {
        name: { first: '', last: '' },
        profilePictures: { upload: '' },
      };
      item.author.name.first = item.user_details.field_first_name_value;
      item.author.name.last = item.user_details.field_last_name_value;
      item.author.profilePictures.upload = item.user_details.thumbnail_preview;
      item.imageURLs = [];
      item.imageURLs.push = item.images ? item.images[0].url : '';
      item.body = { content: '' };
      item.body.content = item.description;
      item.date = { updated: '' };
      item.date.updated = item.created;
      return item;
    });
  }
};

export const recentObject = (obj: any) => {
  const newObj = {
    type: 'feed',
    filter: {
      mystories: {
        me: 1,
        friend: 0,
        others: 0,
        groups: {
          1: 0,
          82: 0,
          136: 0,
          148: 0,
        },
      },
      save_filters: 0,
    },
    configurationTimestamp: '0',
    searchTerm: {
      prompt_pagination: 0,
      start: obj.params?.pageIndex,
      length: obj.params?.pageSize,
      searchString: obj.params?.searchTerm,
      last_memory_date: '',
      request_type: 'older',
    },
    randomPrompts: 0,
  };
  return newObj;
};
export const recentResponse = (obj: any) => {
  const dataArr = obj?.Details;
  if (dataArr) {
    dataArr.data.map((item: any) => {
      item.memoryID = item.nid;
      item.author = {
        name: { first: '', last: '' },
        profilePictures: { upload: '' },
      };
      item.author.name.first = item.user_details.field_first_name_value;
      item.author.name.last = item.user_details.field_last_name_value;
      item.author.profilePictures.upload = item.user_details.thumbnail_preview;
      item.imageURLs = [];
      item.imageURLs.push = item.images ? item.images[0].url : '';
      item.body = { content: '' };
      item.body.content = item.description;
      item.date = { updated: '' };
      item.date.updated = item.updated;
      return item;
    });
  }
  const newResponse = {
    message: obj.ResponseMessage,
    code: obj.ResponseCode,
    page: {
      totalItems: dataArr.count,
      totalPages: 'NA',
      currentPage: 'NA',
      pageSize: 'NA',
    },
    data: dataArr.data,
  };
  return newResponse;
};
export const singleMemoryObject = (id: string) => {
  const newObj = {
    configurationTimestamp: '0',
    memoryDetails: {
      nid: id,
      type: 'my_stories',
    },
  };
  return newObj;
};
export const singleMemoryResponse = (obj: any) => {
  const collabArray: Array<any> = [];
  obj.collaborators.map((item: any, index: number) => {
    collabArray[index] = {
      name: {
        first: item.field_first_name_value,
        last: item.field_last_name_value,
      },
    };
    return item;
  });
  const newObj = {
    title: obj.title,
    collaborators: collabArray,
    author: {
      name: {
        first: obj.user_details.field_first_name_value,
        last: obj.user_details.field_last_name_value,
      },
      profilePictures: {
        upload: obj.user_details.uri,
      },
    },
    location: {
      name: obj.location,
    },
    body: {
      content: obj.description,
    },
    date: {
      created: obj.created,
      occurred: obj.memory_date,
    },
    images: obj.images,
    prompts: obj.prompts,
    challenge_name: obj.challenge_name,
  };
  return newObj;
};
export const getMemoryObject = (id: string) => {
  const newObj = {
    configurationTimestamp: '0',
    details: {
      nid: id,
    },
  };
  return newObj;
};
export const PublishMemoryObject = (obj: any) => {
  const visibility = {
    1: 'only_me',
    2: 'custom',
    3: 'all',
    4: 'cueback',
  };

  const newObj = {
    details: {
      title: obj.data.title,
      memory_date: {
        year: obj.data.date ? obj.data.date?.split('-')[0].toString() : '',
        month: obj.data.date
          ? (obj.data.date?.split('-')[1] * 1).toString()
          : '',
        day: obj.data.date ? (obj.data.date?.split('-')[2] * 1).toString() : '',
      },
      nid: obj.nid,
      share_option: (visibility as any)[obj.data.visibility],
      action: 'publish',
    },
  };
  return newObj;
};

export const draftMemoryResponse = (obj: any) => {
  const newObj = {
    code: obj.ResponseCode,
    message: obj.ResponseMessage,
    // data: [{
    //   memoryID: obj.Status
    // }],
    // etherpad_url: obj.etherpad_url,
    // padid: obj.padid,
    // sessionId: obj.sessionId
  };
  return newObj;
};

export const draftMemoryObject = (obj: any) => {
  const newObj = {
    details: {
      title: obj.data.title,
      memory_date: {
        year: obj.data.date ? obj.data.date?.split('-')[0].toString() : '',
        month: obj.data.date
          ? (obj.data.date?.split('-')[1] * 1).toString()
          : '',
        day: obj.data.date ? (obj.data.date?.split('-')[2] * 1).toString() : '',
      },
      // nid: obj.nid,
      //share_option: visibility[obj.data.visibility],
      // action:'publish'
    },
  };
  return newObj;
};

const baseUrl = () => {
  return process.env.GATSBY_VERCEL_RELEASE_1_2 === 'TRUE' ||
    process.env.GATSBY_AWS_ENV === 'PROD'
    ? 'https://user.api.mystoriesmatter.com'
    : process.env.GATSBY_AWS_ENV === 'QA'
    ? 'https://qa-user.api.mystoriesmatter.com'
    : 'https://dev-user.api.mystoriesmatter.com';
};

/**
 * @description Validate token by the cookie
 * @param context GetServer side context
 * @param redirect Redirect if required to login page. By default true
 * @returns props for serverside props
 */
export const validateToken = async (
  context: GetServerDataProps,
  redirect: boolean = true
) => {
  const cookies = new Cookies(context.headers.get('cookie')!);
  let response = {
    status: 301,
    headers: {
      Location: '/auth/login',
    },
  };
  return axios
    .get(`${baseUrl()}/api/v1/users`, {
      headers: {
        authorization: cookies.get('idToken'),
        refreshToken: cookies.get('refreshToken'),
        platform: '2',
        apptype: '0',
        'Accept-Language': 'en',
      },
    })
    .then((res) => ({
      user: res.data.data,
      props: {
        props: {},
      },
    }))
    .catch((error) => {
      if (redirect) {
        return { user: null, props: response };
      } else {
        return { props: { props: {} }, user: null };
      }
    });
};
