exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-app-tsx": () => import("./../../../src/pages/app.tsx" /* webpackChunkName: "component---src-pages-app-tsx" */),
  "component---src-pages-auth-add-user-tsx": () => import("./../../../src/pages/auth/add-user.tsx" /* webpackChunkName: "component---src-pages-auth-add-user-tsx" */),
  "component---src-pages-auth-error-tsx": () => import("./../../../src/pages/auth/error.tsx" /* webpackChunkName: "component---src-pages-auth-error-tsx" */),
  "component---src-pages-auth-get-details-tsx": () => import("./../../../src/pages/auth/get-details.tsx" /* webpackChunkName: "component---src-pages-auth-get-details-tsx" */),
  "component---src-pages-auth-login-tsx": () => import("./../../../src/pages/auth/login.tsx" /* webpackChunkName: "component---src-pages-auth-login-tsx" */),
  "component---src-pages-auth-password-recovery-email-sent-tsx": () => import("./../../../src/pages/auth/password-recovery/email-sent.tsx" /* webpackChunkName: "component---src-pages-auth-password-recovery-email-sent-tsx" */),
  "component---src-pages-auth-password-recovery-index-tsx": () => import("./../../../src/pages/auth/password-recovery/index.tsx" /* webpackChunkName: "component---src-pages-auth-password-recovery-index-tsx" */),
  "component---src-pages-auth-reset-password-[code]-js": () => import("./../../../src/pages/auth/reset-password/[code].js" /* webpackChunkName: "component---src-pages-auth-reset-password-[code]-js" */),
  "component---src-pages-auth-signup-tsx": () => import("./../../../src/pages/auth/signup.tsx" /* webpackChunkName: "component---src-pages-auth-signup-tsx" */),
  "component---src-pages-auth-thank-you-tsx": () => import("./../../../src/pages/auth/thank-you.tsx" /* webpackChunkName: "component---src-pages-auth-thank-you-tsx" */),
  "component---src-pages-auth-verify-account-[code]-js": () => import("./../../../src/pages/auth/verify-account/[code].js" /* webpackChunkName: "component---src-pages-auth-verify-account-[code]-js" */),
  "component---src-pages-edit-tsx": () => import("./../../../src/pages/edit.tsx" /* webpackChunkName: "component---src-pages-edit-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-memories-index-tsx": () => import("./../../../src/pages/memories/index.tsx" /* webpackChunkName: "component---src-pages-memories-index-tsx" */),
  "component---src-pages-onboarding-tsx": () => import("./../../../src/pages/onboarding.tsx" /* webpackChunkName: "component---src-pages-onboarding-tsx" */),
  "component---src-pages-recent-tsx": () => import("./../../../src/pages/recent.tsx" /* webpackChunkName: "component---src-pages-recent-tsx" */),
  "component---src-pages-settings-tsx": () => import("./../../../src/pages/settings.tsx" /* webpackChunkName: "component---src-pages-settings-tsx" */)
}

