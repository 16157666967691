import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
  Action,
  AnyAction,
} from '@reduxjs/toolkit';
import {
  SetWelcomePopupStatus,
  GetWelcomePopupStatus,
} from '../../../app-api/oldApi';

import { IHeadState } from './head.types';

interface RejectedAction extends Action {
  error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  return action.type.endsWith('rejected');
}

function isLoadingAction(action: AnyAction): boolean {
  return action.type.endsWith('pending');
}

export const SetWelcomePopUpStatus = createAsyncThunk(
  'head/setWelcomePopup',
  async (obj, { rejectWithValue }) =>
    SetWelcomePopupStatus()
      .then((res) => res)
      .catch((err) => rejectWithValue(err))
);

export const GetWelcomePopUpStatus = createAsyncThunk(
  'head/getWelcomePopup',
  async (obj, { rejectWithValue }) =>
    GetWelcomePopupStatus()
      .then((res) => res)
      .catch((err) => rejectWithValue(err))
);

const initialState: IHeadState = {
  title: 'Home',
  theme: 'dark', // UI should be able to accomodate this!
  description: '', // a 2-3 line description of a page,
  welcomePopup: false,
  status: 'idle',
};

const reducers = {
  setTheme: (state: IHeadState, action: PayloadAction<string>) => {
    const theme = action.payload;
    state.theme = theme;
  },
};

const extraReducers = (builder: ActionReducerMapBuilder<IHeadState>) => {
  builder.addMatcher(isRejectedAction, (state, action) => {
    state.status = 'failed';
    state.error = action.error;
  });

  builder.addMatcher(isLoadingAction, (state, action) => {
    state.status = 'loading';
  });
};

export const HeadSlice = createSlice({
  initialState,
  name: 'head',
  reducers,
  extraReducers,
});
export default HeadSlice.reducer;

export const { setTheme } = HeadSlice.actions;
