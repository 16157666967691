import { Axios } from './axios';
import { AxiosRequestConfig, AxiosResponse, AxiosError } from 'axios';
import isBrowser from '../../util/gatsby';
import { navigate } from 'gatsby';
import configData from '@/config/cognitoConfig';

const getIdToken = (): string => {
  if (isBrowser) {
    if (localStorage.getItem('idToken')) {
      return localStorage.getItem('idToken')!;
    } else {
      return (
        localStorage.getItem(
          'CognitoIdentityServiceProvider.' +
            configData.clientId +
            '.' +
            localStorage.getItem(
              'CognitoIdentityServiceProvider.' +
                configData.clientId +
                '.LastAuthUser'
            ) +
            '.idToken'
        ) ?? ''
      );
    }
  }
  return '';
};

const getRefreshToken = (): string => {
  if (isBrowser) {
    if (localStorage.getItem('refreshToken')) {
      return localStorage.getItem('refreshToken')!;
    } else {
      return (
        localStorage.getItem(
          'CognitoIdentityServiceProvider.' +
            configData.clientId +
            '.' +
            localStorage.getItem(
              'CognitoIdentityServiceProvider.' +
                configData.clientId +
                '.LastAuthUser'
            ) +
            '.refreshToken'
        ) ?? ''
      );
    }
  }
  return '';
};

/**
 * @class Api Class is a fancy es6 wrapper class for axios.
 *
 * @param {import("axios").AxiosRequestConfig} config - axios Request Config.
 * @link [AxiosRequestConfig](https://github.com/axios/axios#request-config)
 */

export class Api extends Axios {
  public static token: string;
  public static refreshToken: string;
  /**
   * Creates an instance of api.
   * @param {import("axios").AxiosRequestConfig} conf
   */
  public constructor(conf: AxiosRequestConfig) {
    super(conf);

    Api.token = '';
    this.getToken = this.getToken.bind(this);
    Api.setToken = Api.setToken.bind(this);
    this.getUri = this.getUri.bind(this);
    this.request = this.request.bind(this);
    this.get = this.get.bind(this);
    this.options = this.options.bind(this);
    this.delete = this.delete.bind(this);
    this.head = this.head.bind(this);
    this.post = this.post.bind(this);
    this.put = this.put.bind(this);
    this.patch = this.patch.bind(this);
    this.success = this.success.bind(this);
    this.error = this.error.bind(this);
    Api.refreshToken = '';
    Api.setRefreshToken = Api.setRefreshToken.bind(this);
    this.interceptors.request.use(
      (req) => {
        req.headers = {
          ...req.headers,
          platform: '2',
          apptype: '0',
          'Accept-Language': 'en',
          Authorization: getIdToken(),
          refreshToken: getRefreshToken(),
        };
        return req;
      },
      (error) => error
    );

    // this middleware is been called right before the response is get it by the method that triggers the request
    this.interceptors.response.use(
      (res) => this.success(res),
      (err) => this.error(err)
    );
  }
  /**
   * Gets Token.
   *
   * @returns {string} token.
   * @memberof Api
   */
  public getToken = (): string => {
    if (Api.token) {
      return `Bearer ${Api.token}`;
    }
    return '';
  };
  /**
   * Sets Token.
   *
   * @param {string} token - token.
   * @memberof Api
   */
  public static setToken = (token: string): void => {
    this.token = token;
  };

  /**
   * Gets Refresh Token..
   *
   * @returns {string} token.
   * @memberof Api
   */
  public getRefreshToken = (): string => {
    return `${Api.refreshToken}`;
  };
  /**
   * Sets Refresh Token.
   *
   * @param {string} refreshToken - refreshToken.
   * @memberof Api
   */
  public static setRefreshToken = (refreshToken: string): void => {
    Api.refreshToken = refreshToken;
  };
  /**
   * Get Uri
   *
   * @param {import("axios").AxiosRequestConfig} [config]
   * @returns {string}
   * @memberof Api
   */
  private getUri = (config?: AxiosRequestConfig): string => {
    return this.getUri(config);
  };
  /**
   * Generic request.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP axios response payload.
   * @memberof Api
   *
   * @example
   * api.request({
   *   method: "GET|POST|DELETE|PUT|PATCH"
   *   baseUrl: "http://www.domain.com",
   *   url: "/api/v1/users",
   *   headers: {
   *     "Content-Type": "application/json"
   *  }
   * }).then((response: AxiosResponse<User>) => response.data)
   *
   */
  public request<T, R = AxiosResponse<T>>(
    config: AxiosRequestConfig
  ): Promise<R> {
    return this.request(config);
  }
  /**
   * HTTP GET method, used to fetch data `statusCode`: 200.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   * @memberof Api
   */
  public get<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.get(url, config);
  }
  /**
   * HTTP OPTIONS method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} HTTP `axios` response payload.
   * @memberof Api
   */
  public options<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.options(url, config);
  }
  /**
   * HTTP DELETE method, `statusCode`: 204 No Content.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public delete<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.delete(url, config);
  }
  /**
   * HTTP HEAD method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public head<T, R = AxiosResponse<T>>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.head(url, config);
  }
  /**
   * HTTP POST method `statusCode`: 201 Created.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public post<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.post(url, data, config);
  }
  /**
   * HTTP PUT method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public put<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.put(url, data, config);
  }
  /**
   * HTTP PATCH method.
   *
   * @access public
   * @template T - `TYPE`: expected object.
   * @template B - `BODY`: body request object.
   * @template R - `RESPONSE`: expected object inside a axios response format.
   * @param {string} url - endpoint you want to reach.
   * @param {B} data - payload to be send as the `request body`,
   * @param {import("axios").AxiosRequestConfig} [config] - axios request configuration.
   * @returns {Promise<R>} - HTTP [axios] response payload.
   * @memberof Api
   */
  public patch<T, B, R = AxiosResponse<T>>(
    url: string,
    data?: B,
    config?: AxiosRequestConfig
  ): Promise<R> {
    return this.patch(url, data, config);
  }
  /**
   *
   * @template T - type.
   * @param {import("axios").AxiosResponse<T>} response - axios response.
   * @returns {T} - expected object.
   * @memberof Api
   */
  public success = <T>(response: AxiosResponse<T>): T => {
    return response.data;
  };
  /**
   *
   *
   * @template T type.
   * @param {AxiosError<T>} error
   * @memberof Api
   */
  public error = <T>(error: AxiosError<T>): void => {
    if (error.response) {
      if (
        (error.response.data as any).code === 401 &&
        location.pathname !== '/auth/login' &&
        location.pathname !== '/'
      ) {
        navigate('/auth/login');
      }
    }
    throw error.response?.data;
  };
}
