var colors_R2 = {
  // Neutrals
  neutral600: '11,12,15',
  neutral400: '46,49,62',
  neutral200: '76,83,103',
  neutral150: '193,195,202',
  neutral100: '226,228,233',

  // Blues
  blue600: '5,39,71',
  blue400: '10,77,143',
  blue200: '170,210,248',
  blue100: '217,235,252',

  // pineapples
  pineapple400: '173,135,0',
  pineapple200: '255,238,177,0.25',
  pineapple100: '255,238,177',

  // cucumbers
  cucumber400: '8,130,122',
  cucumber100: '198,244,241',

  // dragonfruits
  dragonfruit400: '161,0,155',
  dragonfruit100: '255,230,254',

  // guavas
  guava400: '198,74,74',
  guava100: '249,236,236',

  // Whites
  white: '255,255,255',
  offWhite: '243,245,247',
  lgtGrey: '248, 248, 250',

  // Black
  black: '0, 0, 0',
  // Error
  error100: '249, 220, 225',
  error200: '242, 176, 187',
  error400: '153, 25, 46',
  error600: '88, 13, 23',

  card_bg_white: '7, 53, 98, 0.05',

  // trash -2
  trash2_border: '55, 65, 81',

  // MessageBG
  messageBg: '248, 248, 250',
  // table row hover
  tab_row_hover: '248, 248, 250',

  rightCkick: '55, 65, 81',

  bgprimary: '237, 239, 253',
  bgsecondary: '249, 230, 215',
  bgtertiary: '206, 238, 254',
  bgquaternary: '234, 207, 241',
  blueberry100:'217,235,252',
  blueberry400:'10,77,143'
};



const theme_R2 = {
  colors: colors_R2,
  // Buttons
  buttonPrimaryBg: `rgb(${colors_R2.neutral600})`,
  buttonPrimaryText: `rgb(${colors_R2.white})`,
  buttonPrimaryBorder: `rgb(${colors_R2.neutral600})`,
  buttonSecondaryBg: `rgb(${colors_R2.offWhite})`,
  buttonSecondaryText: `rgb(${colors_R2.neutral600})`,
  buttonSecondaryBorder: `rgb(${colors_R2.neutral100})`,
  buttonSecondaryBorderHover: `rgb(${colors_R2.neutral200})`,

  // Page Components
  pageTextBody: `rgb(${colors_R2.neutral200})`,
  pageTextListNumberBg: `rgb(${colors_R2.offWhite})`,
  pageTextListNumberColor: `rgb(${colors_R2.neutral200})`,
  sectionBorder: `rgb(${colors_R2.neutral100})`,

  //card Component
  cardBackground: `rgba(${colors_R2.card_bg_white})`,

  // Message Component
  MessageIcon: `rgba(${colors_R2.pineapple400})`,
  MessageIconBg: `rgba(${colors_R2.pineapple100})`,
  MessageBg: `rgba(${colors_R2.messageBg})`,

  // Blue
  Blue_400: `rgba(${colors_R2.blue400})`, // Accent_400
  Blue_600: `rgba(${colors_R2.blue600})`, // Accent_600

  // Eroor

  Error_100: `rgba(${colors_R2.error100})`,
  Error_200: `rgba(${colors_R2.error200})`,
  Error_400: `rgba(${colors_R2.error400})`,
  Error_600: `rgba(${colors_R2.error600})`,

  // Neutral

  Neutral_100: `rgba(${colors_R2.neutral100})`,
  Neutral_150: `rgba(${colors_R2.neutral150})`,
  Neutral_200: `rgba(${colors_R2.neutral200})`,
  Neutral_400: `rgba(${colors_R2.neutral400})`,
  Neutral_600: `rgba(${colors_R2.neutral600})`,

  // Black
  Black: `rgba(${colors_R2.black})`,

  // White
  White: `rgba(${colors_R2.white})`,
  Off_White: `rgba(${colors_R2.offWhite})`,

  // trash
  Trash2_Border: `rgba(${colors_R2.trash2_border})`,

  // table row hover color

  TabRowHover: `rgba(${colors_R2.tab_row_hover})`,
  rightCkick: `rgba(${colors_R2.rightCkick})`,

  Bgprimary: `rgba(${colors_R2.bgprimary})`,
  Bgsecondary: `rgba(${colors_R2.bgsecondary})`,
  Bgtertiary: `rgba(${colors_R2.bgtertiary})`,
  Bgquaternary: `rgba(${colors_R2.bgquaternary})`,
};

const theme = theme_R2;

export default theme;
