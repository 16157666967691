import {
  createSlice,
  createAsyncThunk,
  PayloadAction,
  ActionReducerMapBuilder,
  Action,
  AnyAction,
} from '@reduxjs/toolkit';
import { fetchTags, FriendsList } from '../../../app-api/oldApi';
import { IAddMemoryState } from './addMemory.types';

interface RejectedAction extends Action {
  error: Error;
}

function isRejectedAction(action: AnyAction): action is RejectedAction {
  const regex = new RegExp('addMemory/[a-zA-Z]*/pending');
  return regex.test(action.type);
}
function isLoadingAction(action: AnyAction): boolean {
  const regex = new RegExp('addMemory/[a-zA-Z]*/pending');
  return regex.test(action.type);
}

const initialState: IAddMemoryState = {
  ListData: [],
  TagList: [],
  memoryUid: [],
  groupId: [],
  whoElseMemoryUid: [],
  status: 'idle',
  Data: {
    title: '',
    memory_date: {
      year: '',
      month: '',
      date: '',
    },
    files: [],
    location: {
      description: '',
      reference: '',
    },
    share_option: 'custom',
    action: 'publish',
    description: '',
    collection: '',
    who_can_see_memory_uids: '',
    who_can_see_memory_group_ids: '',
    who_else_was_there_uids: '',
    memory_tags_text: [],
    is_comment_allowed: 1,
  },
};

/**
 * Actions for Adding a memory
 */

export const fetchFriendList = createAsyncThunk<any, any>(
  'addMemory/fetchFriendList',
  async (obj, { rejectWithValue }) => {
    try {
      const response = await FriendsList(obj);
      return response as Array<any>;
    } catch (error) {
      rejectWithValue(error as any);
    }
  }
);
export const fetchTagsAsync = createAsyncThunk(
  'addMemory/fetchTagslist',
  async (obj, { rejectWithValue }) => {
    try {
      const response = await fetchTags(obj);
      return response as Array<any>;
    } catch (error) {
      rejectWithValue(error as any);
    }
  }
);

const reducers = {
  setAttachments: (state: IAddMemoryState, action: PayloadAction<any>) => {
    if (action.payload !== null) state.Data.files.push(action.payload);
  },
  setYear: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.memory_date.year = action.payload;
  },
  setMonth: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.memory_date.month = action.payload;
  },
  setDate: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.memory_date.date = action.payload;
  },
  setAllowComment: (state: IAddMemoryState, action: PayloadAction<number>) => {
    state.Data.is_comment_allowed = action.payload;
  },
  setMemoryTags: (
    state: IAddMemoryState,
    action: PayloadAction<Array<any>>
  ) => {
    state.Data.memory_tags_text = action.payload;
  },
  setMemoryTitle: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.title = action.payload;
  },
  setLocation: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.location.description = action.payload;
  },
  setDescription: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.description = action.payload;
  },
  setMemoryUids: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.who_can_see_memory_uids = action.payload;
  },
  setGroupids: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.who_can_see_memory_group_ids = action.payload;
  },

  setWhoElseWasThere: (
    state: IAddMemoryState,
    action: PayloadAction<string>
  ) => {
    state.Data.who_else_was_there_uids = action.payload;
  },

  setCollection: (
    state: IAddMemoryState,
    action: PayloadAction<{ selected: boolean; tid: string }>
  ) => {
    if (action.payload.selected)
      state.Data.collection_tid += action.payload.tid + ',';
    else {
      state.Data.collection_tid = state.Data.collection_tid?.replace(
        action.payload.tid + ',',
        ''
      );
    }
  },
  DeleteAttachment: (state: IAddMemoryState, action: PayloadAction<string>) => {
    state.Data.files = state.Data.files.filter((item) => {
      return item.fid !== action.payload;
    });
  },
};

/**
 * @description  Used this way of extraReducers to reduce writing types
 * @param builder - Defines type for state and action automatically
 */
const extraReducers = (builder: ActionReducerMapBuilder<IAddMemoryState>) => {
  builder
    .addCase(fetchFriendList.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(fetchFriendList.fulfilled, (state, action) => {
      state.status = 'idle';
      state.ListData = action.payload;
    })
    .addCase(fetchTagsAsync.pending, (state, action) => {
      state.status = 'loading';
    })
    .addCase(fetchTagsAsync.fulfilled, (state, action) => {
      state.status = 'idle';
      state.TagList = action.payload as Array<any>;
    })
    .addMatcher(isRejectedAction, (state, action) => {
      state.status = 'failed';
      state.error = action.error as any;
    })
    .addMatcher(isLoadingAction, (state, action) => {
      state.status = 'loading';
    });
};

export const AddMemorySlice = createSlice({
  name: 'addMemory',
  initialState,
  reducers,
  extraReducers,
});

export default AddMemorySlice.reducer;

export const {
  setAttachments,
  setYear,
  DeleteAttachment,
  setMonth,
  setMemoryTags,
  setMemoryUids,
  setCollection,
  setGroupids,
  setWhoElseWasThere,
  setDate,
  setDescription,
  setAllowComment,
  setMemoryTitle,
  setLocation,
} = AddMemorySlice.actions;
