import {
  createSlice,
  ActionReducerMapBuilder,
  Action,
  AnyAction,
} from '@reduxjs/toolkit';
import { userActions } from './user.actions';
import { IUserState } from './users.types';

interface RejectedAction extends Action {
  payload: any;
  error: Error;
}

function isRejectedAction(action: AnyAction): boolean {
  const regex = new RegExp('user/[a-zA-Z]*/rejected');
  return regex.test(action.type);
}

function isLoadingAction(action: AnyAction): boolean {
  const regex = new RegExp('user/[a-zA-Z]*/pending');
  return regex.test(action.type);
}

const initialState: IUserState = {
  status: 'idle',
  userDetails: null,
  ChangePasswordResponse: {},
  UpdateConfigResponse: {},
  forgotPasswordResponse: '',
  updateUserDetailsResponse: null,
};

const reducers = {
  clearChangePasswordResponse: (state: IUserState) => {
    state.ChangePasswordResponse = {};
  },
  clearUserDetails: (state: IUserState) => {
    state.userDetails = null;
  },
};
const extraReducers = (builder: ActionReducerMapBuilder<IUserState>) => {
  builder
    .addCase(userActions.fetchUserDetails.fulfilled, (state, action) => {
      state.userDetails = action.payload;
      state.status = 'idle';
    })
    .addCase(userActions.changePassword.fulfilled, (state, action) => {
      state.status = 'idle';
      state.ChangePasswordResponse = action.payload;
    })
    .addCase(userActions.UpdateConfig.fulfilled, (state, action) => {
      state.status = 'idle';
      state.UpdateConfigResponse = action.payload;
    })
    .addCase(userActions.updateUserDetails.fulfilled, (state, action) => {
      state.status = 'idle';
      state.updateUserDetailsResponse = action.payload;
    })
    // .addCase(userActions.ResetPasswordRequest.fulfilled, (state, action) => {
    //   state.status = 'idle';
    //   // state.UpdateConfigResponse = action.payload;
    // })
    // .addCase(
    //   userActions.InitiateForgotPasswordRequest.fulfilled,
    //   (state, action) => {
    //     state.status = 'idle';
    //     // state.updateUserDetailsResponse = action.payload;
    //   }
    // )
    .addMatcher(isRejectedAction, (state, action) => {
      state.status = 'failed';
      state.error = action.error;
      state.ChangePasswordResponse = action.payload;
      state.forgotPasswordResponse = action.payload;
    })
    .addMatcher(isLoadingAction, (state, action) => {
      state.status = 'loading';
    });
};

export const UserSlice = createSlice({
  initialState,
  name: 'user',
  reducers,
  extraReducers,
});
export const { clearChangePasswordResponse, clearUserDetails } =
  UserSlice.actions;
export default UserSlice.reducer;
