import React from 'react';
import { Provider } from 'react-redux';
import { store } from './Store';
import { ThemeProvider } from 'styled-components';
import theme from '@/styles/colors';
import { QueryClient, QueryClientProvider } from 'react-query';

const queryClient = new QueryClient();
const WrapWithProvider = ({ element }: any) => {
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <ThemeProvider theme={theme}>{element}</ThemeProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default WrapWithProvider;
