/* eslint-disable import/no-named-as-default */
import { configureStore } from '@reduxjs/toolkit';
import { useDispatch } from 'react-redux';
import AddMemorySlice from './reducers/AddMemory/addMemory.slice';
import HeadSlice from './reducers/Head/head.slice';
import LoginSlice from './reducers/Login/login.slice';
import MemorySlice from './reducers/Memory/memory.slice';
import SignupSlice from './reducers/Signup/signup.slice';
import UserSlice from './reducers/Users/users.slice';
export const store = configureStore({
  reducer: {
    login: LoginSlice,
    signup: SignupSlice,
    memories: MemorySlice,
    head: HeadSlice,
    addMemory: AddMemorySlice,
    user: UserSlice,
  },
  devTools: process.env.NODE_ENV === 'development' ? true : false,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

export const useAppDispatch = () => useDispatch<AppDispatch>();
