import { createAsyncThunk } from '@reduxjs/toolkit';
import { LoginApi, SsoLogin } from '@/app-api/oldApi';
import { userApi } from '@/app-api/newApi/users';
import {
  SignInResponseDto,
  SignInRequestDto,
  FederatedSignInDto,
} from '../../../app-api/newApi/users/dto';
import jwt_decode from 'jwt-decode';
import { loginResponse } from '../util';
import isBrowser from '@/util/gatsby';
import configData from '@/config/cognitoConfig';

const loginWithNewApi = createAsyncThunk(
  'login/withNewApi',
  async (obj: SignInRequestDto, { rejectWithValue, fulfillWithValue }) => {
    try {
      const payload = obj;
      const response: SignInResponseDto = await userApi.signIn(payload);
      if (response.code === 200 && isBrowser) {
        localStorage.setItem('uid', response.data.uid);
        localStorage.setItem('idToken', response.data.token.idToken);
        localStorage.setItem('refreshToken', response.data.token.refreshToken);
        localStorage.setItem('auth_time', response.data.auth_time.toString());
        localStorage.setItem('email', response.data.email);
        localStorage.setItem('exp', response.data.exp.toString());
        localStorage.setItem('fullName', response.data.fullName);
        localStorage.setItem(
          'introductory',
          response.data.config.introductoryData.toString()
        );

        return fulfillWithValue(response);
      }
    } catch (error: any) {
      return rejectWithValue(error);
    }
  }
);

const loginWithOldApi = createAsyncThunk(
  'login/withOldpi',
  async (obj: any, { rejectWithValue, fulfillWithValue }) => {
    try {
      const payload = { ...obj, emailId: obj.email };
      const response = await LoginApi(payload);
      const formattedResponse = loginResponse(response);
      if (formattedResponse.code === 200 && isBrowser) {
        localStorage.setItem('uid', formattedResponse.data.uid);
        localStorage.setItem('idToken', formattedResponse.data.token.idToken);
        localStorage.setItem(
          'refreshToken',
          formattedResponse.data.token.refreshToken
        );
        localStorage.setItem(
          'auth_time',
          formattedResponse.data.auth_time.toString()
        );
        localStorage.setItem('email', formattedResponse.data.email);
        localStorage.setItem('exp', formattedResponse.data.exp.toString());
        localStorage.setItem('fullName', formattedResponse.data.fullName);
        localStorage.setItem(
          'introductory',
          formattedResponse.data.config.introductoryData.toString()
        );
        return fulfillWithValue(formattedResponse);
      } else {
        throw formattedResponse;
      }
    } catch (error) {
      return rejectWithValue(error);
    }
  }
);

const googleLogin = createAsyncThunk(
  'login/withGoogle',
  async (Obj: FederatedSignInDto, { rejectWithValue, fulfillWithValue }) => {
    try {
      const idToken =
        localStorage.getItem(
          'CognitoIdentityServiceProvider.' +
            configData.clientId +
            '.' +
            localStorage.getItem(
              'CognitoIdentityServiceProvider.' +
                configData.clientId +
                '.LastAuthUser'
            ) +
            '.idToken'
        ) ?? '';
      const refreshToken =
        localStorage.getItem(
          'CognitoIdentityServiceProvider.' +
            configData.clientId +
            '.' +
            localStorage.getItem(
              'CognitoIdentityServiceProvider.' +
                configData.clientId +
                '.LastAuthUser'
            ) +
            '.refreshToken'
        ) ?? '';
      const decoded = jwt_decode<any>(idToken);
      const obj = {
        cognitoUid: decoded.sub,
        status: 'EXTERNAL_PROVIDER',
        firstName: decoded.given_name,
        lastName: decoded.family_name,
        primaryEmail: decoded.email,
        authRedirectURL: Obj.url,
      };
      const response = await userApi.federatedSignIn(obj);
      if (response.code === 200 && isBrowser) {
        localStorage.clear();
        localStorage.setItem('uid', decoded.sub);
        localStorage.setItem('idToken', idToken);
        localStorage.setItem('refreshToken', refreshToken);
        localStorage.setItem(
          'auth_time',
          Math.floor(new Date().getTime() / 1000).toString()
        );
        localStorage.setItem('email', decoded.email);
        localStorage.setItem(
          'fullName',
          decoded.given_name + ' ' + decoded.family_name
        );
        localStorage.setItem(
          'introductory',
          response.data.config.introductoryData.toString()
        );
        return fulfillWithValue(response);
      }
    } catch (error) {
      console.log('Error in Social login', error);
      return rejectWithValue(error);
    }
  }
);

const ssoLogin = createAsyncThunk('login/withSSO', async (obj) => {
  const result = await SsoLogin(obj);
  const response = loginResponse(result);
  if (response.code === 200 && isBrowser) {
    localStorage.setItem('uid', response.data.uid);
    localStorage.setItem('idToken', response.data.token.idToken);
    localStorage.setItem('refreshToken', response.data.token.refreshToken);
    localStorage.setItem('auth_time', response.data.auth_time.toString());
    localStorage.setItem('email', response.data.email);
    localStorage.setItem('exp', response.data.exp.toString());
  }
  return response;
});

export const loginActions = {
  loginWithNewApi,
  loginWithOldApi,
  googleLogin,
  ssoLogin,
};
