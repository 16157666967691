const configData = {
  clientId:
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'app-qa' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'auth-env'
      ? '5o9veq7lnv99bq652ral37jla0'
      : process.env.GATSBY_VERCEL_URL
      ? '69jvprlnrn510anfleh8o1odjl'
      : '5o9veq7lnv99bq652ral37jla0',
  userPoolUri:
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'app-qa' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'auth-env'
      ? 'dev-mystoriesmatter.auth.us-east-1.amazoncognito.com'
      : process.env.GATSBY_VERCEL_URL
      ? 'auth.mystoriesmatter.com'
      : 'dev-mystoriesmatter.auth.us-east-1.amazoncognito.com',
  userPool:
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'app-qa' ||
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'auth-env'
      ? 'us-east-1_j3k3Io9Ta'
      : process.env.GATSBY_VERCEL_URL
      ? 'us-east-1_fZHFTwUBV'
      : 'us-east-1_j3k3Io9Ta',
  region: 'us-east-1',
  callbackUri:
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'app-qa'
      ? 'https://app-qa.mystoriesmatter.com/auth/add-user'
      : process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'auth-env'
      ? 'https://auth-test.mystoriesmatter.com/auth/add-user'
      : process.env.GATSBY_VERCEL_URL
      ? 'https://app.mystoriesmatter.com/auth/add-user'
      : process.env.GATSBY_DEPLOYMENT_PLATFORM === 'LOCAL'
      ? 'http://localhost:8000/auth/add-user'
      : 'https://app-qa.mystoriesmatter.com/auth/add-user',
  signoutUri:
    process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'app-qa'
      ? 'https://app-qa.mystoriesmatter.com/'
      : process.env.GATSBY_VERCEL_GIT_COMMIT_REF === 'auth-env'
      ? 'https://auth-test.mystoriesmatter.com/'
      : process.env.GATSBY_VERCEL_URL
      ? 'https://app.mystoriesmatter.com/'
      : process.env.GATSBY_DEPLOYMENT_PLATFORM === 'LOCAL'
      ? 'http://localhost:8000/'
      : 'https://app-qa.mystoriesmatter.com/',
  tokenScopes: ['openid', 'email', 'profile'],
};
export default configData;
